@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
/*        font-family: "Open Sans, sans-serif;";*/

    }
}

.registration {
    opacity: 100%;
}

.registration.fade-out {
    animation: fadeOut 0.5s ease-out;
    opacity: 0%;
}



@keyframes bounceUp {
    0% {
        transform: translateY(0);
    }
    80% {
        transform: translateY(-8px);
    }
    100% {
        transform: translateY(-6px);
    }
}

@keyframes bounceDown {
    0% {
        transform: translateY(-6px);
    }
    80% {
        transform: translateY(1px);
    }
    100% {
        transform: translateY(0px);
    }
}

.bouncy-button.bouncy-button-out {
    animation: bounceDown 0.2s ease-out;
    transform: translateY(-0px);
}

.bouncy-button.bouncy-button-in {
    animation: bounceUp 0.2s ease-out;
    transform: translateY(-6px);
}

.bouncy-button-active {
    transform: translateY(-3px) !important;
}


.loading-panel {
    opacity: 100%;
}

.loading-panel.fade-out {
    animation: fadeOut 0.5s ease-out;
    opacity: 0%;
}

@keyframes fadeOut {
    0% {
        opacity: 100%;
    }
    100% {
        opacity: 0%;
    }
}

.results-panel {
    opacity: 0%;
}

.results-panel.fade-in {
    animation: fadeIn 0.5s ease-out;
    opacity: 100%;
}

@keyframes fadeIn {
    0% {
        opacity: 0%;
    }
    100% {
        opacity: 100%;
    }
}

/*********************/
/*Box Animation*/
/*********************/
.scene {
    width: 200px;
    height: 200px;
    perspective: 1000px;
    position: relative;
    margin: 100px auto;
}

.box {
    width: 100px;
    height: 100px;
    position: relative;
    transform-style: preserve-3d;
    transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
}

.face {
    position: absolute;
    width: 100px;
    height: 100px;
    background-color: #c96;
    border: 2px solid #114b6c;
    border-radius: 10px;
}

.front  { background-color: #10c3c5; transform: rotateY(0deg) translateZ(50px); }
.back   { background-color: #bbf7d0; transform: rotateY(180deg) translateZ(50px); }
.right  { background-color: #ffbf9c; transform: rotateY(90deg) translateZ(50px); }
.left   { background-color: #eb966f; transform: rotateY(-90deg) translateZ(50px); }
.top    { background-color: #fff5dd; transform: rotateX(90deg) translateZ(50px); }
.bottom { background-color: #012a35; transform: rotateX(-90deg) translateZ(50px); }

.shadow {
    width: 120px;
    height: 120px;
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    left: 25%;
    right: 25%;
    transform: translateX(-50%) rotateX(90deg);
    border-radius: 50%;
    filter: blur(8px);
}

/*Tetx effect on lander*/
.text-effect {
    opacity: 1;
    transition: opacity 2s ease-in-out;
}

.text-effect:empty {
    opacity: 0;
}

/*Toastify styles*/
:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #129490!important;
  --toastify-color-warning: #ef3d3d!important;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;

  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;

/*  //Used only for colored theme*/
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;

  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;

/*  // Used when no type is provided*/
/*  // toast("**hello**")*/
  --toastify-color-progress-light: linear-gradient(
    to right,
    #4cd964,
    #5ac8fa,
    #007aff,
    #34aadc,
    #5856d6,
    #ff2d55
  );
/*  // Used when no type is provided*/
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);

/*  // used to control the opacity of the progress trail*/
  --toastify-color-progress-bgo: .2;
}